import { Link as TransLink } from 'gatsby-plugin-react-i18next';
import React from 'react';

export const Link = ({ customClass, to, children, ...rest }: any) => {
  return (
    <TransLink to={to} className={customClass} {...rest}>
      {children}
    </TransLink>
  );
};
