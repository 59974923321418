import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

export const Footer = () => {
  const { t } = useTranslation(['meta', 'common']);
  const social: any[] = t('social', { ns: 'meta', returnObjects: true });

  return (
    <footer className="footer footer-center rounded p-10 text-base-content">
      <div className="text-3xl lg:text-5xl">
        {t('footer.title', { ns: 'common' })}
      </div>
      <div className="bg-white"></div>
      <div className="grid grid-flow-col gap-4">
        {social?.map((item: any) => (
          <a
            key={item.link}
            href={item.link}
            target="_blank"
            className="grayscale transition-all hover:filter-none">
            <img
              className="h-12 w-12 object-contain"
              src={item.image}
              alt={item.title}
            />
          </a>
        ))}
      </div>
      <div>
        <p>
          Copyright © {new Date().getFullYear()} - All right reserved -{' '}
          {t('title')}
        </p>
      </div>
    </footer>
  );
};
