import { StaticImage } from 'gatsby-plugin-image';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Link } from '../Link/animated';

export const Header = ({ slugs }: {
  slugs: any
}) => {
  const { t, i18n } = useTranslation();
  const { languages, defaultLanguage } = useI18next();
  const menu: any = t('menu.items', { returnObjects: true });

  const closeMenu = () => {
    document.getElementById('header-menu-trigger')?.click();
  };

  const getSlug = (lng: string) => {
    if (slugs && slugs[lng]) return lng === defaultLanguage ? `/${slugs[lng]}` : `/${lng}/${slugs[lng]}`
    return lng === defaultLanguage ? `/` : `/${lng}/`
  }

  return (
    <header className="fixed top-0 z-[100] w-full text-white">
      <div className="relative flex h-12 items-center justify-between px-4 pt-7">
        <label htmlFor="header-menu-trigger" className="sr-only">
          Open menu
        </label>
        <input
          id="header-menu-trigger"
          type="checkbox"
          className="menu-toggle sr-only"
        />

        <Link to="/">
          <StaticImage
            className="relative z-30 w-[150px]"
            src="../../../assets/logo.png"
            alt="UKS"
          />
        </Link>

        <label className="menu-trigger" htmlFor="header-menu-trigger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block h-6 w-6 stroke-white">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </label>

        <ul className="menu-content flex items-center justify-center">
          {menu.map((item: any) => (
            <li key={item.link}>
              <Link onClick={closeMenu} to={item.link}>
                {item.title}
              </Link>
            </li>
          ))}

          {languages.map(
            lng =>
              lng != i18n.language && (
                <li key={lng}>
                  <a
                    href={getSlug(lng)}
                    // onClick={onLangChange(lng)}
                    title={`Change language to ${lng}`}>
                    <img
                      className="h-8 w-8 rounded grayscale hover:filter-none"
                      src={`/${lng}.svg`}
                      alt={lng}
                    />
                  </a>
                </li>
              )
          )}
        </ul>
      </div>
    </header>
  );
};
