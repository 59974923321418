import React from 'react';

import CookieConsent from '@/common/components/CookieConsent';
import { Footer } from '@/common/components/Footer';
import { Header } from '@/common/components/Header';
import { AnimatePresence, motion } from 'framer-motion';

export const Layout = ({ children, slugs }: any) => {
  return (
    <>
      <Header slugs={slugs} />
      <main>{children}</main>
      <CookieConsent />
      <Footer />
    </>
  );
};

// Rendered by gatsby-browser.tsx and gatsby-ssr.tsx
export const GatsbyBrowserLayout = ({ children, path }: any) => (
  <>
    {/* <div className="screen-loading"></div> */}
    <AnimatePresence mode="popLayout">
      <motion.div
        key={path}
        initial={{
          opacity: 0,
          x: -40,
        }}
        exit={{
          opacity: 0,
          x: 8,
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        transition={{
          type: 'spring',
          duration: 2,
          ease: 'easeInOut',
        }}>
        {children}
      </motion.div>
    </AnimatePresence>
  </>
);
