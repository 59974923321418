import {useEffect} from 'react';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './styles.css';

const googleTagId = 'GTM-P82MSG7';
const websiteName = 'Urban Kiz Studio';

const gtagInit = () => {
  const gtagContent = document.createElement('script');

  gtagContent.text = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${googleTagId}');
  `;

  document.head.appendChild(gtagContent);
};

export default function CookieConsent() {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        current_lang: document.documentElement.lang,
        autoclear_cookies: true, // default: false
        // page_scripts: true, // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: true,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0
        gui_options: {
          consent_modal: {
            layout: 'box', // box/cloud/bar
            position: 'bottom right', // bottom/middle/top + left/right/center
            transition: 'slide', // zoom/slide
            swap_buttons: true, // enable to invert buttons
          },
          settings_modal: {
            layout: 'box', // box/bar
            // position: 'left',           // left/right
            transition: 'slide', // zoom/slide
          },
        },

        onFirstAction: function (user_preferences, cookie) {
          // ...
        },

        onAccept: function (cookie) {
          gtagInit();
        },

        onChange: function (cookie, changed_preferences) {
          // ...
        },

        languages: {
          hr: {
            consent_modal: {
              title: `${websiteName} koristi kolačiće!`,
              description: `Web stranica ${websiteName} koristi bitne kolačiće kako bi osigurala pravilan rad i kolačiće za praćenje kako bi razumjela kako s njome komunicirate. Određeni kolačići će se postaviti tek nakon pristanka. <button type="button" data-cc="c-settings" class="cc-link">Više</button>`,
              primary_btn: {
                text: 'Prihvati sve',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Odbaci',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Postavke kolačića',
              save_settings_btn: 'Spremi',
              accept_all_btn: 'Prihvati sve',
              reject_all_btn: 'Odbaci',
              close_btn_label: 'Zatvori',
              cookie_table_headers: [
                {col1: 'Name'},
                {col2: 'Domain'},
                {col3: 'Expiration'},
                {col4: 'Description'},
              ],
              blocks: [
                {
                  title: 'Kolačići',
                  description:
                    'Koristim kolačiće kako bih osigurao osnovne funkcionalnosti web stranice i poboljšao vaše online iskustvo. Za svaku kategoriju možete odabrati opciju uključivanja/isključivanja kad god želite. Za više pojedinosti o kolačićima i drugim osjetljivim podacima pročitajte potpuna <a href="/gdpr" class="cc-link">pravila o privatnosti</a>.',
                },
                // {
                //   title: "Strictly necessary cookies",
                //   description:
                //     "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                //   toggle: {
                //     value: "necessary",
                //     enabled: true,
                //     readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                //   },
                // },
                {
                  title: 'Performance and Analytics cookies',
                  description:
                    'Ovi kolačići omogućuju web stranici da zapamti odabire koje ste napravili u prošlosti',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true,
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...',
                    },
                  ],
                },
                // {
                //   title: "Advertisement and Targeting cookies",
                //   description:
                //     "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
                //   toggle: {
                //     value: "targeting",
                //     enabled: false,
                //     readonly: false,
                //   },
                // },
                // {
                //   title: "More information",
                //   description:
                //     'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://dip.hr/kontakt">contact us</a>.',
                // },
              ],
            },
          },
          en: {
            consent_modal: {
              title: `${websiteName} uses cookies!`,
              description: `The website ${websiteName} uses essential cookies to ensure proper operation and tracking cookies to understand how you interact with it. Certain cookies will only be set after consent. <button type="button" data-cc="c-settings" class="cc-link">More</button>`,
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all', // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: 'Decline',
                role: 'accept_necessary', // 'settings' or 'accept_necessary'
              },
            },
            settings_modal: {
              title: 'Cookie settings',
              save_settings_btn: 'Save',
              accept_all_btn: 'Accept all',
              reject_all_btn: 'Decline',
              close_btn_label: 'Close',
              cookie_table_headers: [
                {col1: 'Name'},
                {col2: 'Domain'},
                {col3: 'Expiration'},
                {col4: 'Description'},
              ],
              blocks: [
                {
                  title: 'Cookies',
                  description:
                    'I use cookies to provide basic website functionality and improve your online experience. For each category, you can select the on/off option whenever you want. For more details about cookies and other sensitive data, read the full <a href="/gdpr" class="cc-link">privacy policy</a>.',
                },
                // {
                //   title: "Strictly necessary cookies",
                //   description:
                //     "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                //   toggle: {
                //     value: "necessary",
                //     enabled: true,
                //     readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                //   },
                // },
                {
                  title: 'Performance and Analytics cookies',
                  description:
                    'These cookies allow the website to remember choices you have made in the past',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: [
                    // list of all expected cookies
                    {
                      col1: '^_ga', // match all cookies starting with "_ga"
                      col2: 'google.com',
                      col3: '2 years',
                      col4: 'description ...',
                      is_regex: true,
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'description ...',
                    },
                  ],
                },
                // {
                //   title: "Advertisement and Targeting cookies",
                //   description:
                //     "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
                //   toggle: {
                //     value: "targeting",
                //     enabled: false,
                //     readonly: false,
                //   },
                // },
                // {
                //   title: "More information",
                //   description:
                //     'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://dip.hr/kontakt">contact us</a>.',
                // },
              ],
            },
          },
        },
      });
    }
  }, []);

  return null;
}
